import './App.css';

function App() {
  return (
    <div className="App">
      <h1>tomfoolery.lol</h1>
      <p>soon... 👀</p>
    </div>
  );
}

export default App;
